import axios from '@/utils/axios';

const getProdutos = async (filter) => {
    try {
        return ((await axios.get('estoque/getProdutos', { params: filter })).data).data;
    } catch (err) {
        console.log("ERRO AO BSUCAR PRODUTOS ESTOQUE", err);
        return [];
    }
}

const getEstoqueAtual = async (filter) => {
    try {
        return ((await axios.get('estoque/getEstoqueAtual', { params: filter })).data).data;
    } catch (err) {
        console.log("ERRO AO BSUCAR PRODUTOS ESTOQUE", err);
        return [];
    }
}

const entrada = async (dados) => {
    try {
        return (await axios.post('estoque/entrada', dados)).data;
    } catch (e) {
        console.log("ERRO AO REALIZAR ENTRADA", e);
        return null;
    }
}


const transferir = async (dados) => {
    try {
        return (await axios.post('estoque/transferir', dados)).data;
    } catch (e) {
        console.log("ERRO AO REALIZAR ENTRADA", e);
        return null;
    }
}

const saida = async (dados) => {
    try {
        return (await axios.post('estoque/saida', dados)).data;
    } catch (e) {
        console.log("ERRO AO REALIZAR SAIDA", e);
        return null;
    }
}

const Historico = async (dados) => {
    try {
        return (await axios.post('estoque/historico', dados)).data;
    } catch (e) {
        console.log("ERRO AO BUSCAR HISTORICO", e);
        return null;
    }
}

const EstoqueMinimo = async (cod_custo) => {
    try {
        return ((await axios.post('estoque/estoqueMinimo', { cod_custo })).data).data;
    } catch (e) {
        console.log("ERRO AO BUSCAR HISTORICO", e);
        return null;
    }
    
}
const getResumoAtual = async (dados) => {
    try {
        return ((await axios.post('estoque/getResumoAtual', dados)).data).data;
    } catch (e) {
        console.log("ERRO AO BUSCAR HISTORICO", e);
        return null;
    }
}


const excluirMovimento = async (id) => {
    try {
        return (await axios.post('estoque/excluirMovimento', {id})).data;
    } catch (e) {
        console.log("ERRO AO EXCLUIR HISTORICO", e);
        return null;
    }
}
 

const getMovimentos = async (filtros) => {
    try {
        return (await axios.post('estoque/getMovimentos', { filtros })).data;
    } catch (e) {
        console.log("ERRO AO BUSCAR MOVIMENTOS", e);
        return null;
    }
}

const criarInventario = async (centro_custos) => {
    try {
        return (await axios.post('estoque/criarInventario', { centro_custos })).data;
    } catch (e) {
        console.log("ERRO AO BUSCAR criarInventario", e);
        return null;
    }
}


const storeInventario = async (data) => {
    try {
        return (await axios.post('estoque/storeInventario', data)).data;
    } catch (e) {
        console.log("ERRO AO BUSCAR storeInventario", e);
        return null;
    }
}

const getListaInvetario = async (data) => {
    try {
        return (await axios.post('estoque/listaInventario', data)).data;
    } catch (e) {
        console.log("ERRO AO BUSCAR getListaInvetario", e);
        return null;
    }
}


const getInvetario = async (data) => {
    try {
        return (await axios.post('estoque/getInventario', data)).data;
    } catch (e) {
        console.log("ERRO AO BUSCAR getListaInvetario", e);
        return null;
    }
}

const baixarManual = async (data) => {
    try {
        return (await axios.post('estoque/baixarManual', data)).data;
    } catch (e) {
        console.log("ERRO AO BUSCAR baixarManual", e);
        return null;
    }
}


export default {
    getProdutos,
    getEstoqueAtual,
    entrada,
    transferir,
    saida,
    Historico,
    EstoqueMinimo,
    getResumoAtual,
    excluirMovimento,
    getMovimentos,
    criarInventario,
    storeInventario,
    getListaInvetario,
    getInvetario,
    baixarManual
}